
          @import "src/css/_variables.scss";
        
.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  animation-duration: .25s;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;

  .loading {
    height: 100%;
    width: 100%;
    min-height: 3rem;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
}