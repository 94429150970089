
          @import "src/css/_variables.scss";
        
.defaultInput {
  margin: .25rem 0;
  width: 100%;
  max-width: 18rem;

  input {
    width: 100%;
    padding: 8px 16px;
    background: $base-color;
    color: $default-color;
    border: none;
    appearance: none;
    font-size: 0.75rem;
    font-weight: bold;
    
    &.error {
      border-color: $error-color;
    }
  }
  input:disabled {
    background-color: $disabled-color;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
  label {
    display: block;
    margin-bottom: 5px;

    .required {
      color: red;
      font-weight: bold;
    }
  }
  .errormsg {
    color: $error-color;
  }
}