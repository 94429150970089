
          @import "src/css/_variables.scss";
        
.pressroom {
  margin-top: $menu-height;
  display: flex;
  justify-content: center;
  font-size: .8rem;

  .backLink {
    display: block;
    padding: .25rem 0;
  }

  > div {
    // width: 80%;
    max-width: 800px;

    > h3 {
      display: block;
      text-transform: uppercase;
      font-size: 1.75rem;
      letter-spacing: .2rem;
      padding: 4rem 0;
  
      &::after {
        content: '';
        margin-top: 0.25rem;
        display: block;
        width: 4rem;
        height: 4px;
        background-color: $light-color;
        margin: 0 auto;
      }

      @media screen and (max-width: $mobile) {
        padding: 3rem 0;
      }
    }

    .navigationWrapper {
      padding-left: 2rem;

      @media screen and (max-width: $mobile) {
        padding-left: 0;
        margin-bottom: 2.5rem;
      }
    }
  
    .contact {
      @media screen and (min-width: $mobile) {
        display: flex;
        align-items: flex-end; 
      }
    }

    /* mobile page padding */
    @media screen and (max-width: $mobile) {
      padding: 0 4rem;
    }

    @media screen and (max-width: 600px) {
      padding: 0 2rem;
    }

    @media screen and (max-width: 400px) {
      padding: 0 1rem;
    }
  }
}