
          @import "src/css/_variables.scss";
        
.pressProduct {
  display: block;
  margin-bottom: 1rem;
  .productContainer {
    .imageContainer {
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: auto;
      }
    }
    .textContainer {
      margin-left: .5rem;
      display: block;
      .header {
        text-align: left;
        margin: 0;
        margin-bottom: 0.5rem;
      }
      .pressArticleText {}
    }
  }
}