
          @import "src/css/_variables.scss";
        
.headerImage {
  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: $mobile) {
    height: calc(100vw * 0.45);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}