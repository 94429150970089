
          @import "src/css/_variables.scss";
        
.pressContact {
  width: 100%;

  .imageWrapper {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      max-width: 100px;
      height: auto;
      border-radius: 100%;

      @media screen and (max-width: $mobile) {
        width: 75%;
        max-width: 75px;
      }
    }
  }
  .contactDetails {
    font-size: 14px;

    @media screen and (max-width: $mobile) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: $mobile) {
    padding-left: 1rem;
  }
}