
          @import "src/css/_variables.scss";
        
.productGroups {
  // border-bottom: 1px solid #a7a18e;
  font-size: 0.9rem;
  font-family: "Chronicle Display Semibold";
  color: #a7a18e;
  >ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      position: relative; //padding: 0 .2rem;
      &:not(:last-child)::after {
        content: '/';
        padding: 0 .25rem;
      }
      &.hasSubGroups {
        cursor: default;
        &:hover {
          ul.subGroups {
            display: block;
          }
        }
      }
      span.active {
        // border-bottom: 2px solid #a7a18e;
        font-family: "Chronicle Display Semibold Italic";
        color: #000;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    color: #a7a18e;
    cursor: pointer;
    text-transform: uppercase;
  }
  ul.subGroups {
    min-width: 6rem;
    font-size: .8rem;
    position: absolute;
    display: none; //margin-left: auto;
    //margin-right: auto;
    // left: 0;
    // right: 0;
    border: 1px solid #a7a18e;
    background-color: #fff;
    padding: .5rem;
    left: calc(-25% + .5rem); // transform: translateX(-25%);
    border-top: 0;
    z-index: 1000;
    li {
      //padding-bottom: .25rem;
      display: flex;
      span {
        flex-wrap: wrap;
      }
    }
    li:not(:last-child) {
      margin-bottom: .5rem;
    }
    li:not(:last-child)::after {
      content: '';
    }
  }
}