
          @import "src/css/_variables.scss";
        
.articleContainer {
  margin: 2rem 0 3rem 0;
  label {
    font-weight: bold;
  }
  .imageContainer {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: auto;
    }
  }
  .productInfo {
    text-align: left;
    padding-left: 1rem;
    font-size: 0.8rem;
    .header {
      h2 {
        text-align: left;
        margin: 0;
      }
      .subTitle {
        font-size: 1rem;
        margin-bottom: 2rem;
      }
    }
    .productGroup {
      a {
        text-decoration: none;
      }
    }
    .productDescription {
      margin-top: 1rem;
      label {
        text-transform: uppercase;
      }
      p {
        margin: 0;
        word-break: break-all;
      }
    }
  }
}