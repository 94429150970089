
          @import "src/css/_variables.scss";
        
footer {
  min-height: 178px;
  background: #494949;
  color: #fff;

  .content {
    display: grid;
    padding: 0 10rem;
    grid-template-columns: 1fr 3fr 1fr;
    padding-top: 1rem;

    nav {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-weight: 400;
          font-size: 11px;
          padding: 0.25rem 0;

          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }

    .footer-logo {
      padding-top: 1rem;
    }
    
    .social-media {
      margin-top: 1.5rem;
    }
  }

  .copyright {
    font-size: 10px;
    color: #fff;
    text-align: center;
    padding: 0.25rem 0;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0;

    .content {
      grid-template-columns: 1fr;
      padding: 0 1rem;
      text-align: center;
    }
  }
}