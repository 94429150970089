
          @import "src/css/_variables.scss";
        
.moominProducts {
  padding-left: 2rem;

  h2 {
    text-align: left;
    text-transform: uppercase;

    &::after {
      content: '';
      margin-top: 0.25rem;
      display: block;
      height: 4px;
      background-color: $dark-grey;
      margin: 0 auto;
    }
  }

  .pressProduct {
    display: flex;
    flex-wrap: wrap;

    .product {
      position: relative;
      width: calc(50% - .5rem);
      margin: .375rem 0;
      cursor: pointer;

      &:nth-child(2n) {
        margin-left: 1rem;
      }

      img {
        height: auto;
        width: 100%;
      }

      .readMore {
        display: flex;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        height: 1.5rem;
        width: calc(100% - 1rem);
        padding-left: 1.375rem;
        border: 1px solid #90908F;
        border-radius: 1.5rem;
        text-transform: uppercase;
        font-size: 8px;
        line-height: 1.375rem;
        font-weight: bold;
        color: #90908F;

        .arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -1px;
          right: -1px;
          background: #90908F;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 100%;

          .outline {
            position: relative;
            left: -1px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            height: .25rem;
            width: .25rem;
            transform: rotate(45deg);
          }
        }
      }

      @media screen and (max-width: $mobile) {
        width: calc(33.33% - .75rem);
          &:nth-child(2n) {
            margin-left: 0;
          }
          &:nth-child(3n) {
            margin-left: 1rem;
          }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding-left: 0;
  }
}