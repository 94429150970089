
          @import "src/css/_variables.scss";
        
.defaultWeekInput {
  .weekInput {
    width: 100%;
    appearance: none;
    padding: 8px 16px;
    -webkit-appearance: none;
    background: #e4e4d8;
    color: #222;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: inherit;
    border: none;
  }
  .weekInput::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }
  .weekInput::-webkit-calendar-picker-indicator {
  }

  label {
    display: block;
    margin-bottom: 5px;
  }
}