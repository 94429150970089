
          @import "src/css/_variables.scss";
        
button.button {
  background: #86847a;
  color: #fff;
  font-family: "UniversLTStd-Light";
  text-transform: uppercase;
  border: none;
  padding: 0.5rem;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &.small {
    line-height: 1rem;
    padding: 5px 20px;
    font-weight: bold;
  }
  &.light {
    background: #e4e4d8;
    padding: 0.5rem 1rem;
    color: #222;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0.25rem;
    display: inline-block;
    line-height: inherit;
  }
  &.tiny {
    line-height: .75rem;
    padding: .25rem .5rem;
    font-size: 12px;
  }
  &:disabled {
    background: lighten(#86847a, 25%);
  }
}