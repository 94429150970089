
          @import "src/css/_variables.scss";
        
.setNewPassword {
  div {
    margin-bottom: .5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 1rem;
    }
  }
}