
          @import "src/css/_variables.scss";
        
.invoices {
  position: relative;
  min-height: 8rem;

  .paid {
    color: forestgreen;
  }

  .unpaid {
    color: darkred;
  }

  .pdf {
    cursor: pointer;
  }
}