
          @import "src/css/_variables.scss";
        
.ImageGridSlider {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  animation: fadeIn 1s ease-in;

  .group {
    height: 100%;
    position: absolute;

    display: grid;
    gap: 1rem;
    grid-template-areas:
      "a a b"
      "a a c";

    opacity: 0;
    transition: opacity .5s ease-in-out;

    &.show {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    >:first-child {
      grid-area: a;
    }

    >:nth-child(2) {
      grid-area: b;
      
    }

    >:nth-child(3) {
      grid-area: c;
      
    }
  }
}

// greate key frames to fade in
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}