
          @import "src/css/_variables.scss";
        
.productGroups {
  // border-bottom: 1px solid #a7a18e;
  font-size: 0.9rem;
  font-family: "Chronicle Display Semibold";
  color: #a7a18e;
  >ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      position: relative;
      //padding: 0 .2rem;
      &:not(:last-child)::after {
        content: '/';
        padding: 0 .25rem;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    color: #a7a18e;
    cursor: pointer;
    text-transform: uppercase;
  }
  .active {
    border-bottom: 2px solid #a7a18e;
    font-family: "Chronicle Display Semibold Italic";
    color: #000;
  }
}