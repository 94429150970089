
          @import "src/css/_variables.scss";
        
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      width: 1.5rem;
      height: 1.5rem;
      font-family: Arial, Helvetica, sans-serif;
      color: #fff;
      font-size: 11px;
      text-align: center;
      border: 1px solid $border-color;
      background-color: $dark-color;

      &.arrow {
        padding-bottom: .1rem;
      }

      &:not(:last-child) {
        margin-right: .375rem;
      }

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }

      &.active {
        color: $default-color;
        background-color: lighten($dark-color, 20%);
        box-shadow: none;
        cursor: default;
      }
    
  }
}