
          @import "src/css/_variables.scss";
        
@import "flexboxgrid";
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,100,500,600,700);

@font-face {
  font-family: "Brandon Grotesque Bold";
  src: url("font/Brandon_bld.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Grotesque Regular";
  src: url("font/Brandon_reg.otf") format("opentype");
}

@font-face {
  font-family: "Chronicle Display Semibold";
  src: url("font/ChronicleDisp-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Chronicle Display Semibold Italic";
  src: url("font/ChronicleDisp-SemiboldItal.otf") format("opentype");
}

@font-face {
  font-family: "UniversLTStd-Light";
  src: url("font/UniversLTStd-Light.otf") format("opentype");
}

html {
  line-height: 1.4;
}

body {
  margin: 0;
  padding: 0;
  font-family: "UniversLTStd-Light";
  font-size: 1em;
  line-height: 1.4;
  color: $default-color;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  text-align: center;
  font-family: "Chronicle Display Semibold";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.86);
}

h4 {
  font-family: "Chronicle Display Semibold";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.86);
  margin: 0;
}

h3 {
  display: block;
  margin: 0 auto;
  font-weight: lighter;
}

a {
  cursor: pointer;
  color: $default-color;
}

.logoContainer {

  h1,
  h2,
  h3 {
    text-align: center;
    font-family: "Chronicle Display Semibold";
    text-transform: uppercase;
  }
}

.underline {
  text-transform: uppercase;
}

.underline::after,
.logoContainer::after {
  content: '';
  margin-top: 0.25rem;
  display: block;
  width: 4rem;
  height: 4px;
  background-color: #e4e4d8;
  margin: 0 auto;
}

.brodText {
  font-family: "UniversLTStd-Light";
  font-size: 0.8rem;
  font-weight: lighter;
}

.submitButton {
  position: relative;
  top: 1rem;
  width: 6rem;
  height: 6rem;
  border: none;
  border-radius: 4rem;
  background: black;
  color: white;
  margin: 0 auto 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  line-height: 1.4;
  padding: 0;
  cursor: pointer;
}

.downLoadImage {
  width: 100%;
  background-color: #e4e4d8;
  height: 1.5rem;
  bottom: 0;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-family: "Brandon Grotesque Regular", sans-serif;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}

.downLoadImage::after {
  content: " ▼";
}

table.default {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem; //table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  &>thead {
    th {
      font-family: "UniversLTStd-Light", sans-serif;
      font-size: 0.8rem;
      padding: 0.5rem;
      color: #fff;
      background: #86847a;
      font-weight: normal;
      text-align: left;
    }
  }

  &>tbody {
    tr {
      &:nth-child(even) {
        background: rgb(228, 228, 216);
      }

      &:nth-child(odd) {
        background: #fff;
      }

      td {
        padding: 4px;
        padding-left: .5rem;
        padding-right: .5rem;
        font-size: 0.8rem;
      }
    }
  }

  &>thead>tr>th,
  &>tbody>tr>td {
    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }
  }

  .noRows {
    font-style: italic;
  }
}

table.subrows {
  &>tbody:nth-child(odd) {
    >tr {
      background: rgb(228, 228, 216);
    }
  }

  tr.subtable {
    &>td {
      // padding: 0 0 .25rem;
      padding: 0;
      background: #fff;
    }

    table {
      width: 100%;
      margin: 0; // padding: 4rem;
      border: 1px solid $border-color;
      border-collapse: collapse;

      &>tbody {
        tr:nth-child(even) {
          background: rgb(214, 214, 205);
        }

        tr:nth-child(odd) {
          background: #fff;
        }
      }

      th {
        color: #fff;
        background: #86847a;
        font-weight: normal;
        padding-left: .5rem;
        padding-right: .5rem;
      }

      td {
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }
  }
}

i.arrow {
  border: solid rgba(0, 0, 0, 0.6);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;

  &.left {
    transform: rotate(135deg);
  }

  &.right {
    transform: rotate(-45deg);
  }

  &.up {
    transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
  }
}

.headnav {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}