
          @import "src/css/_variables.scss";
        
.page {
  padding: 10rem 0;
  margin: auto;

  .imgContainer {
    display: grid;
    // grid 2 columns 8 rows
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(13, 1fr);
    height: 660px;
    width: 100%;
    max-width: 545px;
    gap: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    overflow: hidden;

    // image 1 take 1 column and 6 rows
    >img:first-child {
      grid-column: 1 / 2;
      grid-row: 1 / 8;
    }

    // image 2 take 1 column 4 rows
    >img:nth-child(2) {
      grid-column: 2 / 2;
      grid-row: 1 / 7;
    }

    // image 3 take 1 column 4 rows
    >img:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 8 / 14;
    }

    // image 4 take 1 column 6 rows
    >img:nth-child(4) {
      grid-column: 2 / 2;
      grid-row: 7 / 14;
    }
  }

  .textContainer {
    h1 {
      font-size: 2.2rem;
    }

    div {
      text-align: start;
    }
  }

  .text {
    padding-left: 2rem;
  
    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding: 0 2rem;
    }
  }
}