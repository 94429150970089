
          @import "src/css/_variables.scss";
        
.searchWidget {
  position: relative;
  .toggleWrapper {
    .button {
      width: 18rem;
    }
  }
  form {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    flex-flow: column;
    max-width: 100%;
    width: 18rem;
    display: flex;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #86847a;
    font-weight: bold;
    z-index: 999;
    transition: all 0.1s ease-in-out;

    &.show {
      padding: 0.5rem;
      max-height: 330px;
      border: 2px solid #f3f3ee;
      background-color: #fff;
    }
    
    .searchByColor {
      margin: 0.25rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .newItemsOnly {
      margin: 0.25rem;
      label {
        cursor: pointer;
        letter-spacing: 0.1rem;
        input {
          vertical-align: middle;
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }
      }
    }
    .submitWrapper {
      text-align: right;
    }
  }
}