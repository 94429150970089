
          @import "src/css/_variables.scss";
        
.topmenu {
  height: 2.5rem;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  .menu {
    background: #e4e4d8;
    min-height: 2.5rem;
    line-height: 1.6rem;
    font-size: 1em;
    font-family: "Chronicle Display Semibold";
    display: flex;
    justify-content: center;

    .containerLinks {
      display: flex;
      z-index: 1000;

      .links {
        display: flex;
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        padding: 0;

        li {
          //padding-top: .5rem;
          display: flex;
          align-items: flex-end;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.5rem;
            letter-spacing: -0.05rem;
            text-transform: uppercase;
            padding: 1rem;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.86);
            background-color: transparent;
          }

          &.active {
            background: #565655;
            font-family: "Chronicle Display Semibold Italic";

            a {
              color: #fff;
            }
          }
        }
      }
    }

    .languages {
      left: 1rem;
      top: .5rem;
      position: absolute;
      display: flex;
      width: 6rem;
      margin-left: .5rem;

      > div {
        padding: .25rem;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .topmenu {
    position: fixed;
    top: 0;
    left: 0;

    .menu {
      justify-content: unset;

      .containerLinks {
        position: absolute;
        background: #e4e4d8;
        right: 50px;

        &.hidden {
          display: none;
        }

        .links {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;

          li {
            padding: 0.5rem 0.6rem;

            a {}
          }
        }
      }

      .languages {
        display: flex;
        width: 4rem; //margin-left: .5rem;
        margin: 0;
      }

      .languages a {
        padding: .25rem; //display: flex;
        //align-items: flex-start;
      }

      .hamburger {
        position: absolute;
        right: 10px;
        top: 7px;
        width: 25px;
        height: 30px;

        div {
          width: 25px;
          height: 2px;
          background-color: rgba(0, 0, 0, 0.86);
          margin: 6px 0;
        }
      }
    }
  }
}