
          @import "src/css/_variables.scss";
        
.cookieFeature {
  display: block;
  border-top: 1px solid #000;
  background-color: #fff;
  padding: 12px;
  z-index: 100;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;

  .cookieFeatureContainer {
    .cookieLink {
      margin-left: .5rem;
      text-decoration: underline;
      cursor: pointer;
    }
    .cookieAcceptButton {
      margin-left: .5rem;
      text-transform: none;
    }
  }
}