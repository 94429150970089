
          @import "src/css/_variables.scss";
        
.defaultTextArea {
  textarea {
    resize: vertical;
    background: $base-color;
    color: $default-color;
    border: none;
    &.error {
      border-color: $error-color;
    }
  }
  input:disabled {
    background-color: $disabled-color;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  .errormsg {
    color: $error-color;
  }
}