
          @import "src/css/_variables.scss";
        
.Intro {
  .slider {
    margin-top: 3rem;
  }

  .introText {
    padding: 1rem 5rem 0;

    h1 {
      text-align: left;
    }

    p {
      font-family: "UniversLTStd-Light";
      font-size: 0.8rem;
      font-weight: lighter;
    }
  }
}