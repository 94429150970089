
          @import "src/css/_variables.scss";
        
.userProfile {
  position: relative;

  .firstGroup {
    margin-top: 1rem;
    text-align: start;
    div {
      margin: auto;
    }
  }
  .secondGroup {
    margin-top: 1rem;
    text-align: start;
    div {
      margin: auto;
    }
  }
  .pwInput {
    form {
      max-width: 18rem;
      width: 100%; // margin-top: 3rem;
      // margin-bottom: .5rem;
      margin: auto;
      button {
        margin-top: .5rem;
      }
    }
  }
}