
          @import "src/css/_variables.scss";
        
.gallerySlider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    position: relative;
    max-height: 85vh;
    max-width: 85vw;
    user-select: none;
    overflow: hidden;

    div.arrow {
      cursor: pointer;
      position: absolute;
      background: rgba(238, 238, 238, 0.411);
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: ease-in-out .2s opacity;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      z-index: 101;

      &.back {
        left: 1rem;
      }

      &.forward {
        right: 1rem;
      }

      > i.arrow {
        position: absolute;
        top: calc(50% - .5rem);

        &.left {
          left: calc(50% - .35rem);
        }

        &.right {
          right: calc(50% - .35rem);
        }
      }
    }

    &:hover {
      .arrow {
        opacity: 1;
      }
    }

    img {
      height: auto;
      width: 100%;
    }

    .textArea {
      background: rgba(0, 0, 0, 0.274);
      color: #fff;
      position: absolute;
      bottom: .25rem;
      padding: .25rem;
      left: 0;
      right: 0;
      opacity: 0;
      
      &.show {
        transition: ease-in-out .25s opacity;
        opacity: 1;
      }
    }
  }
}