
          @import "src/css/_variables.scss";
        
.pressGallery {
  > h3 {
    margin: 1rem;
  }

  > .loadingSpinner {
    margin-top: 3rem;
  }
}