
          @import "src/css/_variables.scss";
        
.pressMessage {
  .pressMessageContainer {
    padding-top: 1rem;
    >.date {
      text-transform: uppercase;
    }
    >.header {
      h2 {
        text-align: left;
        margin: .5rem 0;
      }
    }
  }
}