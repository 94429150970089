$site-width: 74rem;
$base-color: #e4e4d8;

$dark-color: #86847A;
$light-color: #E4E4D8;
$default-color: #222;
$bg-color: #fff;
$border-color: #86847A;
$light-grey: #F9F9F9;
$dark-grey: #EAEAEA;
$dark-green: #0A7B7A;

$mobile: 768px;
$tablet: 960px;
$desktop: 1200px;

$menu-height: 2.5rem;
$mobile-page-padding: .5rem;

$error-color: red;

$disabled-color: #eee;

$screen-xs-max: 768px;
$screen-sm-min: 769px;
$screen-sm-max: 970px;
$screen-md-min: 971px;
$screen-md-max: 1170px;
$screen-lg-min: 1171px;
$screen-lg-max: 1600px;
$screen-xl-min: 1601px;