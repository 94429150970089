
          @import "src/css/_variables.scss";
        
.deliveryAddresses {
  .selectContainer {
    max-width: 18rem;
    width: 18rem;
  }
  .checkBoxContainer {
    max-width: 18rem;
    width: 18rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    input {
      margin-top: .3rem;
    }
  }
  .buttonWrapper {
    margin-top: .75rem;
    display: flex;
    justify-content: center;
  }
}