
          @import "src/css/_variables.scss";
        
.pressNavigation {
  ul {
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      width: 100%;
      list-style-type: none;
      border-top: 1px solid $dark-grey;
      padding: .875rem .25rem;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 13px;
      text-align: center;

      &.image {
        padding: 0;
        height: 2.625rem;
      }

      img {
        width: auto;
        height: 100%;
      }

      &:last-child {
        border-bottom: 1px solid $dark-grey;
      }

      &:hover,
      &.active {
        background-color: $light-grey;
      }

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: $mobile) {
        padding: .675rem .25rem;

        &.image {
          height: 2.25rem;
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    max-width: 280px;
  }
}