
          @import "src/css/_variables.scss";
        
.deliveryaddresses {
  position: relative;

  table {
    th {
      &.button {
        width: 20%;
      }
    }
    td {
      &.button {
        button {
          margin: 0 .1rem;
        }
      }
    }
  }
}