
          @import "src/css/_variables.scss";
        
.galleryItem {
  display: flex;
  justify-content: center;
  position: relative;
  width: 12rem;
  height: 12rem;
  overflow: hidden;
  margin: .25rem;
  cursor: pointer;

  img {
    position: absolute;
    height: 100%;
    width: auto;
  }
}