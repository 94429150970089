
          @import "src/css/_variables.scss";
        
.loggedIn {
  text-align: right;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  font-family: "UniversLTStd-Light";
  font-weight: bold;
  .nameHeader {
    font-weight: normal;
  }
  .buttonWrapper {
    button:first-child {
      margin-right: .25rem;
    }
  }
}