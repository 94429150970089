
          @import "src/css/_variables.scss";
        
.termsPage {
  height: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;

  div {
    max-width: 40rem;
    margin: 0 auto;
  }
  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 40px;
      max-height: 40px;
      margin-bottom: .5rem;
    }
  }
  .textContainer {
    div {
      text-align: left;
      @media screen and (max-width: $mobile) {
        padding: $mobile-page-padding;
      }
    }
  }
}