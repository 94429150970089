
          @import "src/css/_variables.scss";
        
.cartWidget {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: .2rem;
  button {
    margin-top: .5rem;
  }
}