
          @import "src/css/_variables.scss";
        
.resellerForm {
  font-size: 0.8rem;
  max-width: 40rem;
  margin: auto;
  .logoContainer::after {
    background-color: #000;
  }
  h1 {
    letter-spacing: 0.25rem;
    font-size: 2.2rem;
  }
  .form {
    line-height: 1.4;
    div:first-child:not(.defaultInput) {
      padding-right: 2rem;
      @media screen and (max-width: $mobile) {
        padding-right: 0;
      }
    }
    div {
      label {
        text-align: left;
        font-style: italic;
        font-size: 0.8rem;
        margin-bottom: 0;
      }
      textarea {
        font-size: 1.2rem;
        line-height: 1.4;
        width: 100%;
        font-weight: 100;
      }
    }
  }
  .defaultInput {
    margin: 0;
    font-size: 1.2rem;
    padding: 0.25rem 0;
    //font-weight: 100;
    font-weight: lighter;
    max-width: none;
  }
}