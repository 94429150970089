
          @import "src/css/_variables.scss";
        
.orders {
  position: relative;
  min-height: 8rem;

  table {
    th {
      &.button {
        width: 10%;
      }
    }
  }

  .subtable {
    th.articleNumber {
      width: 12%;
    } 
  }

  .tracking {
    margin-left: .375rem;
  }
}