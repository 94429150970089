
          @import "src/css/_variables.scss";
        
.loginWidget {
  width: 100%;
  position: relative;

  .buttonWrapper {
    display: flex;
    justify-content: space-between;
  }

  .error {
    color: red;
  }
}