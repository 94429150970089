
          @import "src/css/_variables.scss";
        
.productCard{
  position:relative;
  margin:0.5rem;
  width:12rem;
  border:1px solid #e4e4d8;
  height:20.75rem;
  overflow:hidden;

  .textContainer {
    width: 100%;
    text-align: left;
  }

  &.tall {
    height: 28.25rem;

    p {
      margin-top: 0;  
    }
  }

  .imageContainer {
    background-size: cover;
    width:calc(100% - 1rem);
    height: 251px;
    margin:0.5rem;
    transition: all 0.25s ease-in-out;
  }

  h4 {
    position:relative;
    text-transform: none;
    font-size:1rem;
    margin:-0.5rem 0.5rem 0.125rem 0.5rem;
    font-family: "Chronicle Display Semibold";
    height: 45px;
    z-index:3;
    transition:all 0.25s ease-in-out;
  }

  p {
    position:relative;
    margin:0.125rem 0.5rem;
    font-size:0.8rem;
    z-index:3;
    transition:all 0.25s ease-in-out;
    /*margin-top: 1.5rem;*/
  }
}

.productCard .showInfo, .productCard .addToBasket {
  display:block;
  margin:0.5rem auto;
  width:calc(100% - 1rem);
  height:1.5rem;
  font-size:0.75rem;
  font-style:normal;
  font-weight: lighter;
  text-align:center;
  background-color:#e4e4d8;
  line-height:1.5rem;
  transition:all 0.25s ease-in-out;
}
.productCard .addToBasket{
  top:2.5rem;
  cursor: pointer;
}
.productCard .productPriceContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .regularPrice {
    color: #999; 
    font-size: 0.9rem; 
    text-decoration: line-through;
    margin-left: 0;
  }
  .discountedPrice {
    .priceValue {
      font-size: 1rem; 
      margin-right: 0;
      font-weight: bold;
      color: #d9534f;
    }
  }
  .productPrice {
    font-size: 1rem;
  }
}
.productCard button{
  background-color:#e4e4d8;
  line-height:1.5rem;
  border:none;
  font-size:0.8rem;
}
.productCard .buttonContainer select{
  display: block;
  width: calc(100% - 1rem);
  height:1.5rem;
  margin: 0 0.5rem;
  padding: 0 0.5rem 0 30%;
  font-size:0.7rem;
  font-style:normal;
  font-weight: lighter;
  text-align:center;
  background-color:#e4e4d8;
  line-height:1.5rem;
  transition:all 0.25s ease-in-out;
  border:none;
}
.productCard  .moreInfo{
  position:absolute;
  height:calc(20rem + 1px);
  bottom:-20.5rem;
  left: -1px;
  border-left:1px solid #e4e4d8;
  width:calc(200% + 4px + 1rem); /* add 2px for border */   
  background:white;

  z-index:3;
  transform: scaleX(0.5) scaleY(0);
  transform-origin: top left;
  transition:transform 0.25s ease-in-out;
}

.productCard.left  .moreInfo{
  left: calc(-100% - 1rem - 3px);
  transform-origin: top right;
}

/* line 1, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.productCard a {
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}

/* line 6, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.kopInfo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0rem;
  width: 100%;
  color: #000;
  font-size: 1rem;
  padding: 0.5rem;
  z-index: 5;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  transition: all, 0.75s, ease-in-out;
}

/* line 18, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.kopInfo.visible {
  opacity: 1;
}

/* line 23, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.productCard .new,
.productCard .sale {
  position: absolute;
  top: 20px;
  right: -70px;
  -webkit-transform: rotate(45deg) translateZ(0px);
          transform: rotate(45deg) translateZ(0px);
  width: 200px;
  height: 25px;
  text-align: center;
  color: #fff;
  margin-right: 2px;
  line-height: 25px;
  z-index: 3;
  background-color: #86847a;
  letter-spacing: 1px;
  font-size: .9em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* line 40, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.productCard .sale {
  background-image: linear-gradient(to bottom, #FF2E2B 0%, #FF2E2B 100%);
}

/* line 43, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.productCard .sale ~ .new {
  top: 40px;
  right: -55px;
}

/* line 47, /var/www/mittditt.ny-webb.se/htdocs/styles/sass/components/_product-card.scss */
.productCard .new ~ .sale {
  top: 40px;
  right: -55px;
}