
          @import "src/css/_variables.scss";
        
.pressMessageCard {
  border-bottom: 1px solid $dark-grey;
  margin: 1.5rem 0;
  padding-bottom: 1.5rem;
  .headerImage {
    max-width: 800px;
    width: 100%;
    height: auto;
    border: 1px solid $dark-grey;
  }
  .contentWrapper {
    @media screen and (min-width: $mobile) {
      &.col-sm-4 {
        padding-left: 1rem;
      }
    }
    >.date {
      text-transform: uppercase;
    }
    >.header {
      h2 {
        text-align: left;
        margin: .5rem 0;
      }
    }
    >.messageTypeWrapper {
      margin-bottom: .5rem;
      display: flex;
      .messageType {
        display: inline-block;
        background: $dark-green;
        line-height: 1.25rem;
        padding: .3rem 1rem;
        font-size: 12px;
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
      }
    }
    >.readMore {
      margin-top: .25rem;
      text-transform: uppercase;
    }
  }
}