
          @import "src/css/_variables.scss";
        
.cookiePage {
  max-width: 50rem;
  line-height: 1.4;
  @media screen and (max-width: $mobile) {
    max-height: 25rem;
    overflow: scroll;
  }
  .cookieTextContainer {
    font-family: "UniversLTStd-Light";
    font-size: .875rem;
    font-weight: lighter;
    text-align: left;
    margin-bottom: .5rem;
    
  }
  .buttonContainer {
    .closeCookieButton {
      text-transform: none;
    }
  }
}