
          @import "src/css/_variables.scss";
        
.defaultSelect {  
  margin: .25rem 0;

  select {
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    background: #e4e4d8;
    color: #222;
    border: none;
    display: block;
    appearance: none;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: inherit;
    padding-right: 2.5rem;

    &.error {
      border-color: $error-color;
    }
  }

  label {
    display: block;
    margin-bottom: 5px;
  }
  
  div.button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    position: relative;
    display: block;
    margin-bottom: none;

    &::before {
      content: '';
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background: #86847a;
      position: absolute;
      display: block;
      pointer-events: none;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12.1px 7px 0 7px;
      border-color: #ddd transparent transparent transparent;
      transform: rotate(360deg);
      top: 12px;
      right: 8px;
      position: absolute;
      pointer-events: none;
    }
  }

  .errormsg {
    color: $error-color;
  }
}

.defaultSelectNoArrow {  
  select {
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    background: #e4e4d8;
    color: #222;
    border: none;
    display: block;
    appearance: none;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: inherit;

    &.error {
      border-color: $error-color;
    }
  }

  label {
    display: block;
    margin-bottom: 5px;
  }
  
  

  .errormsg {
    color: $error-color;
  }
}

/*
div.button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  position: relative;
  display: block;
  margin-bottom: none;

  &::before {
    content: '';
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background: #86847a;
    position: absolute;
    display: block;
    pointer-events: none;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12.1px 7px 0 7px;
    border-color: #ddd transparent transparent transparent;
    transform: rotate(360deg);
    top: 12px;
    right: 8px;
    position: absolute;
    pointer-events: none;
  }
}


*/