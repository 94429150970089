
          @import "src/css/_variables.scss";
        
.resellerPage {
  background-color: rgb(228, 228, 216);
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: calc(100vh - 2.5rem);
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 5%;
    .applyButton {
      font-size: 1rem;
      text-transform: none;
      padding: 4px 16px;
    }
  }
}