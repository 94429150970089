
          @import "src/css/_variables.scss";
        
header.Header {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  .cartInfoWidget {
    text-align: right;
  }


  .headerLogo {
    display: flex;
    justify-content: center;
  }

  .cartInfoWidget {
    max-width: 100%;
    font-size: 0.75rem;
    width: 18rem;
    label {
      display: block;
    }
    .row {
      display: block;
      margin: 0.5rem;
    }
  }
  @media screen and (max-width: 560px) {
    .cartInfoWidget {
      text-align: left;
    }
  }
  @media screen and (max-width: 850px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .cartInfoWidget {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: $mobile) {
    margin-top: 2rem + $menu-height;
  }
}