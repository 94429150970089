
          @import "src/css/_variables.scss";
        
.saidAboutUs {
  position: relative;
  min-height: 8rem;
  
  .quotes {
    .quote {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0;

      .content {
        font-size: 15px;
      }
      .author {
        border-top: 2px solid $border-color;
        margin-top: .25rem;
      }
    }
  }
}