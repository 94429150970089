
          @import "src/css/_variables.scss";
        
.floatingWindow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2000;
  &>.container {
    position: absolute;
    background: #fff;
    color: #000;
    box-shadow: 0.25rem 0.25rem 1.25rem rgb(0, 0, 0);
    max-height: calc(96vh);
    max-width: 96%;
    min-width: 10rem;
    &>.header {
      width: 100%;
      height: 2rem;
      background-color: grey;
      line-height: 2rem;
      display: flex;
      justify-content: space-between;
      h4 {
        color: white;
        margin: 0;
        padding-left: .5rem;
        width: 100%;
      }
      &>.headerCloseButton {
        padding: 0rem 0.5rem;
        color: white;
        cursor: pointer;
        font-weight: bold;
      }
    }
    &>.content {
      max-height: calc(96vh - 2rem);
      /* display: flex;
      flex-flow: column nowrap;
      padding: 0.5rem;
      flex: 1; */
      border: 1px solid transparent;
      padding: 1rem;
      //overflow: hidden;
      //overflow-y: scroll;
    }
  }
}