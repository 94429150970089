
          @import "src/css/_variables.scss";
        
.app {
  max-width: 70rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
  }
}
