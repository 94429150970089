
          @import "src/css/_variables.scss";
        
.cart {
  position: relative;
  font-size: 0.8rem;
  @media screen and (max-width: $mobile) {
    padding: $mobile-page-padding;
  }
  textarea {
    resize: none;
    width: 100%;
    max-width: 35rem;
    min-height: 4rem;
    padding: 0.5rem;
  }
  .selectContainer {
    div {
      margin-right: .5rem;
      @media screen and (max-width: $mobile) {
        margin: 0;
      }
      button {
        margin-top: 1.4rem;
      }
      .defaultInput {
        margin: 0;
        @media screen and (max-width: $mobile) {
          max-width: unset;
        }
      }
    }
  }
  table {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;

    .artnr {
      text-align: right;
      width: 50px;
      a {
        text-decoration: none;
      }
    }
    .ben {
      @media screen and (max-width: $mobile) {
        padding-left: 5px;
      }
      text-align: left;
      padding-left: 30px;
      a {
        text-decoration: none;
      }
    }
    .antal {
      text-align: right;
      width: 90px;
    }
    .pris {
      text-align: right;
      width: 90px;
    }
    .summa {
      text-align: right;
      width: 130px;
      padding-right: 30px;
      @media screen and (max-width: $mobile) {
        padding-right: 5px;
      }
    }
    .radera {
      text-align: left;
      width: 60px;
    }
    .tom {
      text-align: left;
      padding-left: 20px;
      @media screen and (max-width: $mobile) {
        padding-left: 5px;
      }
    }
    .summatext {
      text-align: right;
    }
    a {
      color: inherit;
    }
    a:hover {
      color: #abaa9d;
    }

    .antal {
      .change {
        cursor: pointer;
        padding: .375rem .25rem;

        &.addQuantity {
          color: green;
        }

        &.removeQuantity {
          color: red;
        }

        &.disabledQuantity {
          color: grey;
        }
      }
    }

    .remove {
      cursor: pointer;
    }
  }
  .defaultWeekInput {
    div {
      padding: .5rem;
      background: #e4e4d8;
      display: flex;
      position: relative;
      height: 2rem;
      span {
        display: flex;
        flex: 1;
        label {
          margin: 0; //padding-bottom: .4rem;
        }
        select {
          flex: 1;
          padding-left: .2rem;
          //padding-top: .1rem;
          -webkit-appearance: none;
          appearance: none;
          border: none;
          outline: none;
          user-select: none;
          background: #e4e4d8;
        }
      }
    }
  }
  .errorMessage {
    padding: 1rem 0;
    color: red;
    font-weight: bold;
    text-align: center;
  }
}