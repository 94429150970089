
          @import "src/css/_variables.scss";
        
.resellerCountry {
  .textContainer {
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto;
    font-size: 0.8rem;
    @media screen and (max-width: $mobile) {
      padding: $mobile-page-padding;
    }
    div {
      width: 100%;
      max-width: 32.375rem;
    }
    .customerRow {
      display: flex;
      font-family: "UniversLTStd-Light"; //font-size: 0.8rem;
      width: 100%;
      margin: auto;
      text-align: left;
      .city {
        flex: 0.3;
      }
      .customer {
        flex: 0.7;
      }
    }
  }
}