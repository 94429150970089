
          @import "src/css/_variables.scss";
        
.htmlContainer {
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-right {
    text-align: right;
  }
  p {
    margin: 0;
    padding: 0;
  }
}