
          @import "src/css/_variables.scss";
        
.HeaderBasic {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: $mobile) {
    margin-top: 2rem + $menu-height;
  }
}