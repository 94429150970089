
          @import "src/css/_variables.scss";
        
.products {
  margin-top: $menu-height;

  .loadingSpinner {
    margin-top: 2rem;
  }

  &>.topContainer {
    margin: 2rem auto 1rem auto;
    width: 100%;

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: .2rem;
    }

    h3 {
      font-family: "Chronicle Display Semibold";
      text-transform: uppercase;
    }

    .productGroup {
      ::after {
        content: '';
        margin-top: 0.25rem;
        display: block;
        width: 4rem;
        height: 4px;
        background-color: #e4e4d8;
        margin: 0 auto;
      }
    }
  }

  //   & > .topContainer::after {
  //     content: '';
  //     margin-top: 0.25rem;
  //     display: block;
  //     width: 4rem;
  //     height: 4px;
  //     background-color: #e4e4d8;
  //     margin: 0 auto;
  // }

  &>.productsWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 70rem;
    margin: 0 auto;
    padding-bottom: 3rem;
  }
}