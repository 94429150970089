
          @import "src/css/_variables.scss";
        
.colorCheckbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: .1rem;

  label {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    position: relative;
    border: 1px solid #666;
    cursor: pointer;
  }

  input {
    display: none;

    &:checked+label:before {
      content: '';
      border: 1px solid #000;
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      left: -2px;
      top: -2px;
    }
  }

  span {
    margin-left: .25rem;
  }
}