
          @import "src/css/_variables.scss";
        
.newsLetters {
  & > h3 {
    margin: 1rem;
  }

  .mce-error-response, .mce-success-response {
    display: none;
  }

  .validation {
    position: absolute; left: -5000px;
  }

  .interestGroups {
    label {
      display: flex;
      align-items: center;

      input {
        margin-right: .25rem;
      }
    }
  }

  fieldset {
    border: 1px solid $border-color;
    padding: .75rem;
  }

  .content__gdpr {
    margin-top: 1rem;
  }

  .content__gdpr {
    h3 {
      text-align: left;
    }
  }

  .gdpr {
    margin: 1rem 0;
  }
}