
          @import "src/css/_variables.scss";
        
.contactPage {
  height: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;

  div {
    
    margin: 0 auto;
  }

  h4 {
    font-weight: bold;
    font-family: "UniversLTStd-Light";
  }

  .workersContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    font-size: 0.8rem;

    text-align: center;

    .workerName {
      font-weight: bold;
    }

    a {
      text-decoration: none;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 40px;
      max-height: 40px;
      margin-bottom: .5rem;
    }
  }

  .contactContainer {
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-size: 0.8rem;
    font-family: "UniversLTStd-Light";
    text-align: center;

    img {
      height: auto;
      width: 150px;
    }
  }

  @media screen and (max-width: 769px) {
    .workersContainer {
      grid-template-columns: 1fr;
    }
  }
}